import React from 'react';

const Href = ({ children, to }) => (
  <span>
    <a className={`font-medium text-cyan-600 hover:text-cyan-500`} href={to}>
      {children}
    </a>
  </span>
);

export { Href };
