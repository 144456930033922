import React from 'react';

import { Layout } from '../components/layout';
import { Menu, MenuItem } from '../components/menu';
import { Team, TeamMember } from '../components/team';
import { Title, Paragraph } from '../components/copy';

import avatarDavid from '../assets/david.jpg';
import avatarMark from '../assets/mark.jpg';
import avatarMilan from '../assets/milan.jpg';
import avatarRonja from '../assets/ronja.jpg';

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Title>Wie zijn wij</Title>

    <Paragraph>
      Wij zijn David Middelbeek en Mark van der Linden, samen goed voor vele
      jaren werkervaring als maatschappelijk werkers. Wij hebben onder andere
      gewerkt met mensen met een (licht) verstandelijke beperking,
      psychiatrische problematiek, chronische ziekte of lichamelijke beperking.
    </Paragraph>

    <blockquote className="pl-6 py-2 mb-6 text-xl border-l-4 font-serif">
      "Heldere communicatie en duidelijke afspraken vinden wij belangrijk."
    </blockquote>

    <Paragraph>
      De affiniteit met de doelgroep vormt een belangrijke basis voor het werk
      als bewindvoerder. Onze kracht ligt in een respectvolle en eerlijke
      benadering van de cliënt, waarbij zijn/haar belangen voorop staan.
    </Paragraph>

    <Menu>
      <MenuItem to="/bewindvoering">Bewindvoering ></MenuItem>
      <MenuItem to="/budgetbeheer">Budgetbeheer ></MenuItem>
      <MenuItem to="/mentorschap">Mentorschap ></MenuItem>
      <MenuItem to="/curatele">Curatele ></MenuItem>
      <MenuItem to="/tarieven">Tarieven ></MenuItem>
    </Menu>

    <Team className="mb-8">
      <TeamMember
        email="david@ml-bewindvoering.nl"
        imageAlt="Foto van David"
        imageSrc={avatarDavid}
        name="David Middelbeek"
        phone="06-33316209"
      />
      <TeamMember
        email="mark@ml-bewindvoering.nl"
        imageAlt="Foto van Mark"
        imageSrc={avatarMark}
        name="Mark van der Linden"
        phone="06-33330897"
      />
      <TeamMember
        email="ronja@ml-bewindvoering.nl"
        imageAlt="Foto van Ronja"
        imageSrc={avatarRonja}
        name="Ronja van Harskamp"
        phone="06-19618377"
      />
      <TeamMember
        email="milan@ml-bewindvoering.nl"
        imageAlt="Foto van Milan"
        imageSrc={avatarMilan}
        name="Milan Middelbeek"
        phone="06-49379883"
      />
    </Team>
  </Layout>
);

export default IndexPage;
