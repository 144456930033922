import React from 'react';
import Link from 'gatsby-link';

function Menu({ children }) {
  return (
    <ul className="py-6 mb-8 border-t border-b border-gray-500 border-dashed flex flex-col md:flex-row md:gap-6">
      {children}
    </ul>
  );
}

function MenuItem(props) {
  return (
    <li className="py-1 text-lg">
      <MenuLink {...props} />
    </li>
  );
}

function MenuLink({ className = '', children, to }) {
  return (
    <Link
      className={`font-medium text-cyan-600 hover:text-cyan-500 ${className}`}
      to={to}
    >
      {children}
    </Link>
  );
}

export { Menu, MenuItem, MenuLink };
