import React from 'react';
import { Href } from './href';

function Team({ children, className }) {
  return (
    <div
      id="team"
      className={`grid md:grid-flow-row md:grid-cols-2 md:grid-rows-2 gap-8 ${className}`}
    >
      {children}
    </div>
  );
}

function TeamMember({
  className = '',
  email,
  imageAlt,
  imageSrc,
  name,
  phone,
}) {
  return (
    <div className={`text-center`}>
      <img
        alt={imageAlt}
        src={imageSrc}
        className="w-48 mx-auto rounded-full mb-4"
      />
      <p className="flex flex-col gap-1">
        <span className="text-lg">{name}</span>
        <Href to={`mailto:${email}`}>{email}</Href>
        <Href to={`tel:${phone.split('-').join('')}`}>{phone}</Href>
      </p>
    </div>
  );
}

export { Team, TeamMember };
