import React from 'react';

export function Title({ children, small = false }) {
  return (
    <h1 className={`${small ? 'text-2xl' : 'text-3xl'} mb-6 font-bold`}>
      {children}
    </h1>
  );
}

export function Paragraph({ children }) {
  return <p className="mb-6">{children}</p>;
}
